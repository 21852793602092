@font-face {
    font-family: "Raleway";
    src: url("./Fonts/Raleway/Raleway-Regular.ttf");
}
@font-face {
    font-family: "Raleway-Bold";
    src: url("./Fonts/Raleway/Raleway-Bold.ttf");
}
@font-face {
    font-family: "Raleway-ExtraBold";
    src: url("./Fonts/Raleway/Raleway-ExtraBold.ttf");
}
@font-face {
    font-family: "Raleway-SemiBold";
    src: url("./Fonts/Raleway/Raleway-SemiBold.ttf");
}
@font-face {
    font-family: "Raleway-Medium";
    src: url("./Fonts/Raleway/Raleway-Medium.ttf");
}
@font-face {
    font-family: "Recoleta-Bold";
    src: url("./Fonts/Recoleta/Recoleta-Bold.ttf");
}
@keyframes p {
    from {
        --p: 0;
    }
}
:root {
    /* Colors: */
    --white: #ffffff;
    --backgroundlight: #f1f6f9;
    --backgroundmedium: #c3daea;
    --nightblue: #233155;
    --sunsetblue: #3b3dbb;
    --dayblue: #82c5dd;
    --sunyellow: #fcc55e;
    --signal_error: #f45b5b;
    --signal_succes: #6bce90;
    --unnamed-color-233155: #233155;
    --unnamed-color-23315580: #23315580;

    /* Font/text values */
    --unnamed-font-family-raleway: Raleway;
    --unnamed-font-family-raleway-medium: Raleway-Medium;
    --unnamed-font-family-raleway-bold: Raleway-Bold;
    --unnamed-font-family-raleway-semiBold: Raleway-SemiBold;
    --unnamed-font-family-raleway-extraBold: Raleway-ExtraBold;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-600: 600;
    --unnamed-font-weight-800: 800;
    --unnamed-font-weight-medium: 500;
    --unnamed-font-size-14: 14px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-21: 21px;
    --unnamed-font-size-32: 32px;
    --unnamed-font-size-24: 24px;
    --unnamed-font-size-30: 30px;
    --unnamed-font-size-36: 36px;
    --unnamed-font-size-40: 40px;
    --unnamed-font-size-48: 48px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-1-4: 1.4px;
    --unnamed-character-spacing-1-8: 1.8px;
    --unnamed-line-spacing-21: 21px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-line-spacing-30: 30px;
    --unnamed-line-spacing-40: 40px;
    --unnamed-line-spacing-35: 35px;
    --unnamed-line-spacing-50: 44px;
    --unnamed-line-spacing-64: 64px;
    --unnamed-line-spacing-74: 56px;
}

/* Character Styles */
.n_header-1 {
    font-family: var(--unnamed-font-family-raleway-extraBold);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-800);
    font-size: var(--unnamed-font-size-48);
    line-height: var(--unnamed-line-spacing-74);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.n_header-2 {
    font-family: var(--unnamed-font-family-raleway-extraBold);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-800);
    font-size: var(--unnamed-font-size-36);
    line-height: var(--unnamed-line-spacing-50);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.n_header-3 {
    font-family: var(--unnamed-font-family-raleway-extraBold);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-800);
    font-size: var(--unnamed-font-size-24);
    line-height: var(--unnamed-line-spacing-35);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.n_header-4 {
    font-family: var(--unnamed-font-family-raleway-extraBold);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-800);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-30);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.n_intro {
    font-family: var(--unnamed-font-family-raleway-medium);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-24);
    line-height: var(--unnamed-line-spacing-35);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.n_paragraph {
    font-family: var(--unnamed-font-family-raleway-medium);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-30);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.n_caption {
    font-family: var(--unnamed-font-family-raleway-medium);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-21);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.n_chapeau_b {
    font-family: var(--unnamed-font-family-raleway);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-600);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: var(--unnamed-character-spacing-1-4);
    color: var(--unnamed-color-23315580);
    }

.App {
    background: var(--backgroundlight) 0% 0% no-repeat padding-box;
    background: #f1f6f9 0% 0% no-repeat padding-box;
    opacity: 1;
}
.momentioHome {
    min-height: calc(100vh - 70px);
    background: transparent linear-gradient(180deg, #c3daea 0%, #f1f6f9 100%) 0%
        0% no-repeat padding-box;
}
#root {
    background: #f1f6f9;
}
.fixed-header-container {
    background: 0% 0% no-repeat padding-box padding-box rgb(195, 218, 234);
    /* position: relative; */
    height: 70px;
    position: fixed;
    z-index: 110;
    top: 0;
    width: 100%;
}
.header-container {
    height: 70px;
    display: flex;
    background: var(--backgroundmedium) 0% 0% no-repeat padding-box;
    box-sizing: border-box;
}
.auth-header-container {
    padding: 0 32px;
}
body,
html {
    margin: 0;
}
.header-title-section {
    display: flex;
    font: normal normal bold 32px/44px Recoleta-Bold;
    letter-spacing: 0px;
    color: #233155;
    width: 30%;
    align-items: center;
    position: relative;
}
.header-title-section-login {
    display: flex;
    font: normal normal bold 32px/44px Recoleta-Bold;
    letter-spacing: 0px;
    color: #233155;
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
}
.header-menu-section {
    display: flex;
    color: #233155;
    width: 70%;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}
.header-menu-section-sm {
    display: none;
    width: 80% !important;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding-right: 10px;
}
.header-menu-item {
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-21)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: 0px;
    color: #233155;
    cursor: pointer;
    text-decoration: none !important;
    flex-flow: unset !important;
}
.header-title-item {
    cursor: pointer;
    text-decoration: none !important;
    display: flex;
    flex-grow: 0.1;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold 32px/44px Recoleta-Bold;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: 0px;
    color: #233155;
}
.sunset-blue-tile {
    background: var(--sunsetblue) 0% 0% no-repeat padding-box !important;
    background: #3b3dbb 0% 0% no-repeat padding-box !important;
    color: white;
    height: fit-content;
    height: -moz-fit-content;
}
.sunset-blue-tile div {
    color: white !important;
}
.content-one {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-48) / var(--unnamed-line-spacing-74)
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    margin-bottom: 20px;
    opacity: 1;
}
.auth-content .content-one {
    margin-bottom: 0px;
}
.content-grid-50,
.content-grid-50-sm {
    width: 50%;
}
.image-container {
    width: 100%;
    display: flex;
    justify-content: start;
}
.image > img {
    height: inherit;
    width: inherit;
    border-radius: inherit;
}
.image {
    width: 165px;
    height: 220px;

    box-shadow: 0px 8px 16px #3b3dbb40;
    border-radius: 24px;
    margin-bottom: 25px;
}
.image-section-3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 120px;
    width: 33%;
}
.union-icon {
    width: 62px;
    height: 62px;

    border: 1px solid #00000000;
    opacity: 1;
}
.union-icon-1 {
    background: transparent url("../Images/Union 1.svg") 0% 0% no-repeat
        padding-box;
}
.union-icon-2 {
    background: transparent url("../Images/Path 29.svg") 0% 0% no-repeat
        padding-box;
}
.union-icon-3 {
    background: transparent url("../Images/Union 2.svg") 0% 0% no-repeat
        padding-box;
}
.union-icon-container {
    height: 62px;
    display: flex;
    margin-bottom: 30px;
    padding-left: 10%;
}
.media-icon-container {
    border-radius: 36px;
    margin-right: 16px;
    height: 36px;
    width: 36px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: var(--backgroundlight) 0% 0% no-repeat padding-box;
    background: #f1f6f9 0% 0% no-repeat padding-box;
}
.momentio-youtube-link {
    width: 16px;
    height: 11px;
    cursor: pointer;
    background: transparent url("../Images/Path 22.svg") 0% 0% no-repeat
        padding-box;
}
.momentio-facebook-link {
    width: 8px;
    height: 16px;
    cursor: pointer;
    background: transparent url("../Images/Path 21.svg") 0% 0% no-repeat
        padding-box;
}
.momentio-insta-link {
    width: 16px;
    cursor: pointer;
    height: 13px;
    background: transparent url("../Images/Group 5.svg") 0% 0% no-repeat
        padding-box;
}
.momentio-snapchat-link {
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: transparent url("../Images/Group 3.svg") 0% 0% no-repeat
        padding-box;
}
.playpause {
    height: 66px;
    display: flex;
    width: 66px;
    position: absolute;
    margin-left: 45%;
    margin-top: 35%;
    z-index: 111;
    border-radius: 50%;
    background: white;
    align-items: center;
    justify-content: center;
}

.playpause label {
    outline: none !important;
    display: block;
    box-sizing: border-box;
    width: 0;
    height: 12px;
    border-color: transparent transparent transparent orange;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: solid;
    border-width: 12px 0 12px 16px;
    margin-left: 10px;
}
.playpause input[type="checkbox"] {
    position: absolute;
    left: -9999px;
}
.playpause input[type="checkbox"]:checked + label {
    margin-left: 0;
    border-style: double;
    border-width: 0px 0 0px 13px;
}
.image-section-4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 55px;
    width: 33%;
}
.image-section-2 {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-bottom: 45px;
    width: 33%;
}
.image-section-1 {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-top: 45px;
    width: 33%;
}
.text1 {
    text-align: center;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-64)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-1-8);
    color: #23315580;
}
.text2 {
    text-align: center;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-36) / var(--unnamed-line-spacing-50)
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #233155;
}
.text3 {
    margin-top: 28px;
    text-align: center;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-24) /
        var(--unnamed-line-spacing-35) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #233155;
}
.text4 {
    display: flex;
    justify-content: space-around;
}
.text5 {
    width: 33%;
    position: relative;
}

.add-contact.mm-form-container {
    width: 510px;
    padding: 20px;
}
.text-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 24px;
    font: 800 24px / 35px "League Spartan";
    letter-spacing: 2.4px;
    color: rgb(35, 49, 85);
    height: 44px;
}
body {
    opacity: 1;
    overflow-x: hidden;
}
.mainContainer {
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 115px;
}

.contentColorColumn {
    float: left;
    width: 100px;
}
.contentColorColumn > div {
    width: 100px;
    height: 100px;
    opacity: 1;
}
.backgroundMedium {
    background: #c3daea 0% 0% no-repeat padding-box;
}
.backgroundLight {
    border: 1px solid #c3daea;
}
.backgroundWhite {
    background: #ffffff 0% 0% no-repeat padding-box;
}
.nightBlue {
    background: #233155 0% 0% no-repeat padding-box;
}
.nightBlue-75 {
    background: #233154bf 0% 0% no-repeat padding-box;
}
.nightBlue-50 {
    background: #23315480 0% 0% no-repeat padding-box;
    opacity: 1;
}
.nightBlue-25 {
    background: #23315440 0% 0% no-repeat padding-box;
}
.sunsetBlue {
    background: #3b3dbb 0% 0% no-repeat padding-box;
}
.sunsetBlue-75 {
    background: #3b3dbbbf 0% 0% no-repeat padding-box;
}
.sunsetBlue-50 {
    background: #3b3dbb80 0% 0% no-repeat padding-box;
}
.sunsetBlue-25 {
    background: #3b3dbb40 0% 0% no-repeat padding-box;
}
.dayBlue {
    background: #82c5dd 0% 0% no-repeat padding-box;
}
.dayBlue-75 {
    background: #82c5ddbf 0% 0% no-repeat padding-box;
}
.dayBlue-50 {
    background: #82c5dd80 0% 0% no-repeat padding-box;
}
.dayBlue-25 {
    background: #82c5dd40 0% 0% no-repeat padding-box;
}
.sunYellow {
    background: #fcc55e 0% 0% no-repeat padding-box;
}
.sunYellow-75 {
    background: #fcc55ebf 0% 0% no-repeat padding-box;
}
.sunYellow-50 {
    background: #fcc55e80 0% 0% no-repeat padding-box;
}
.sunYellow-25 {
    background: #fcc55e40 0% 0% no-repeat padding-box;
}
.color20 {
    background: transparent linear-gradient(180deg, #82c5dd 0%, #3b3dbb 100%) 0%
        0% no-repeat padding-box;
}
.header1-normal {
    text-align: left;
    font: normal normal 800 64px/74px Raleway;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
    margin-top: 50px;
}
.chapeau-large-normal {
    width: 534px;
    height: 18px;
    text-align: left;
    font: normal normal 600 18px/64px Raleway;
    letter-spacing: 1.8px;
    color: #23315580;
    margin-bottom: 14px;
    opacity: 1;
}
.chapeau-small-normal {
    width: 304px;
    height: 18px;
    text-align: left;
    font: normal normal 600 14px/64px Raleway;
    letter-spacing: 1.4px;
    color: #23315580;
    opacity: 1;
}
.intro-normal {
    width: 304px;
    height: 36px;
    text-align: left;
    font: normal normal 500 24px/35px Raleway;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
    margin-top: 27px;
}
.dashboard-description {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-24) /
        var(--unnamed-line-spacing-35) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    opacity: 1;
    margin-top: 13px;
    margin-bottom: 40px;
    width: 90%;
}
.header3-normal {
    width: 296px;
    height: 35px;
    text-align: left;
    font: normal normal 800 24px/64px Raleway;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
}
.header4-normal {
    width: 296px;
    height: 23px;

    text-align: left;
    font: normal normal 800 18px/30px Raleway;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
    margin-top: 27px;
}
.breadtext-normal {
    width: 296px;
    height: 27px;
    text-align: left;
    font: normal normal 500 18px/30px Raleway;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
}
.breadtext-small {
    width: 296px;
    height: 27px;
    color: var(--nightblue);
    text-align: left;
    font: normal normal 500 16px/30px Raleway;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
}
.breadtext-small-small {
    width: 296px;
    height: 27px;
    color: var(--nightblue);
    text-align: left;
    font: normal normal 500 12px/30px Raleway;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
    margin-top: 27px;
}
.rectangle {
    width: 220px;
    height: 126px;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
}
.rectangle-shadow {
    width: 220px;
    height: 126px;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #3b3dbb40;
    border-radius: 24px;
    opacity: 1;
}
.buttonContainer > div {
    display: inline-block;
}
button a,
.MuiIconButton-label a {
    font: inherit;
    color: inherit;
    display: inline-block;
    text-decoration: inherit;
    width: 100%;
}
tbody .MuiTableCell-root {
    max-width: 200px;
    text-align: left;
    font: normal normal bold 18px/35px Raleway-Bold;
    letter-spacing: 0px;
    color: #233155;
    padding: 12px 2px 12px 25px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.MuiPaper-elevation1 {
    box-shadow: none !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 24px !important;
}
.contactAccordion .MuiIconButton-edgeEnd {
    margin-right: 0px !important;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall {
    text-align: left;
    font: normal normal 600 14px/24px Raleway-SemiBold !important;
    letter-spacing: 1.4px;
    padding: 23px 2px 23px 25px !important;
    color: #23315580;
}

.filesNormalText {
    font: normal normal 500 18px/35px Raleway !important;
}
.contacts-content {
    /* font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-18)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway); */
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-30)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #233155;
}
.contact-summary-header {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #233155;
}
.contacts-add-link {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-30)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--sunsetblue);
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
}
.contacts-table-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-medium) !important;
    letter-spacing: var(--unnamed-character-spacing-1-4) !important;
    text-align: left;
    color: #23315580;
}
.contacts-headers {
    width: 175px;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
}
.myfiles-content-container {
    padding: 28px 32px 32px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.primary-button-red {
    border: none;
    padding: 0 24px;
    background: #f45b5b 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    text-align: left;
    cursor: pointer;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #ffffff;
    height: 54px;
    outline: none !important;
}
.primary-button-blue {
    border: none;
    padding: 0 24px;
    background: #3b3dbb 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #ffffff;
    cursor: pointer;
    height: 54px;
    outline: none !important;
}
.primary-alert-blue {
    background: #3b3dbb 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    cursor: pointer;
    width: 54px;
    border: none;
    height: 54px;
    outline: none !important;
}
.primary-alert-blue > i {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.primary-alert-yellow {
    background: #fcc55e 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    cursor: pointer;
    width: 54px;
    height: 54px;
    border: none;
    outline: none !important;
}
.primary-alert-yellow > i {
    font-weight: 600;
    font-size: 16px;
}
.secondary-alert {
    outline: none !important;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border: 2px solid #253252;
    border-radius: 27px;
    opacity: 1;
    cursor: pointer;
    width: 54px;
    height: 54px;
}

.secondary-alert > i {
    font-weight: 600;
    font-size: 16px;
}
.secondary-button {
    background-color: transparent;
    outline: none !important;
    padding: 0 24px;
    border: 2px solid #253252;
    cursor: pointer;
    border-radius: 27px;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #233155;
    padding: 10px 24px;
    opacity: 1;
}
.primary-button-yellow {
    outline: none !important;
    cursor: pointer;
    background: #fcc55e 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #1b3548;
    border: none;
    padding: 10px 24px;
}
/*.primary-button-blue-hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}

.primary-button-red:hover {
  box-shadow: 0px 8px 16px #f45b5b !important;
}
.primary-button-blue:hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}

.primary-button-yellow-hover {
  box-shadow: 0px 8px 16px #25325280 !important;
}
.primary-button-yellow:hover {
  box-shadow: 0px 8px 16px #25325280 !important;
}
.secondary-button-hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px #25325280;
}
.secondary-button:hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 8px 16px #25325280 !important;
}
.primary-button-blue-active {
  background: #3b3dbb 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.primary-button-red:hover {
  background: #f45b5b 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.primary-button-blue:active,
.primary-button-blue:focus {
  background: #3b3dbb 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}

.primary-button-yellow-active {
  background: #fcc55e 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.primary-button-yellow:active,
.primary-button-yellow:focus {
  background: #fcc55e 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.secondary-button-active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.secondary-button:active,
.secondary-button:focus {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.primary-alert-blue-hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}
.primary-alert-blue:hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}

.primary-alert-yellow-hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}
.primary-alert-yellow:hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}
.secondary-alert-hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 8px 16px #23315580 !important;
}
.secondary-alert:hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 8px 16px #23315580 !important;
}
.primary-alert-blue-active {
  box-shadow: none !important;
}
.primary-alert-blue:active,
.primary-alert-blue:focus {
  box-shadow: none !important;
}
.primary-alert-yellow-active {
  box-shadow: none !important;
}
.primary-alert-yellow:active,
.primary-alert-yellow:focus {
  box-shadow: none !important;
}
.secondary-alert-active {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none !important;
}
.secondary-alert:active,
.secondary-alert:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none !important;
}*/
.primary-button-blue-sm {
    border: none;
    padding: 10px 24px;
    background: #3b3dbb 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #ffffff;
    outline: none !important;
}
.primary-alert-blue-sm {
    background: #3b3dbb 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    width: 44px;
    height: 44px;
    border: none;
    outline: none !important;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
}
.primary-alert-blue-sm svg {
    width: 22px !important;
    height: 22px !important;
}
.primary-alert-blue-sm > i {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.primary-alert-yellow-sm {
    background: #fcc55e 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    width: 44px;
    height: 44px;
    border: none;
    outline: none !important;
}
.primary-alert-yellow-sm > i {
    font-weight: 600;
    font-size: 16px;
}
.secondary-alert-sm,
.tag-item:last-child {
    outline: none !important;
    border: 2px solid #253252;
    border-radius: 27px;
    opacity: 1;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
}
.ewill-secondary-alert-sm {
    outline: none !important;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border: 2px solid #253252;
    border-radius: 27px;
    opacity: 1;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
}

.secondary-alert-sm > i {
    font-size: 19px;
}
.add-contact .form-field {
    position: relative;
    margin-bottom: 10px;
}

.secondary-alert-sm > svg {
    width: 24px;
    height: 24px;
}
.MuiBackdrop-root {
    background: var(--sunsetblue) 0% 0% no-repeat padding-box !important;
    opacity: 0.75 !important;
}
.alert-badge .MuiBadge-badge {
    padding: 0 4px;
    top: 8px;
    right: 8px;
    font-size:  var(--unnamed-font-size-14);
    font-weight: bold;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    height: 16px;
    min-width: 16px;
    border-radius: 8px;
}
.secondary-button-sm {
    background-color: transparent;
    outline: none !important;
    padding: 10px 24px;
    border: 2px solid #253252;
    border-radius: 27px;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
}
.primary-button-yellow-sm {
    outline: none !important;
    background: #fcc55e 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    border-radius: 27px;
    opacity: 1;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway);
    letter-spacing: 0px;
    color: #1b3548;
    border: none;
    padding: 10px 24px;
}
/*.primary-button-blue-sm-hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}
.primary-button-blue-sm:hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}

.primary-button-yellow-sm-hover {
  box-shadow: 0px 8px 16px #25325280 !important;
}
.primary-button-yellow-sm:hover {
  box-shadow: 0px 8px 16px #25325280 !important;
}
.secondary-button-sm-hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px #25325280;
}
.secondary-button-sm:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px #25325280;
}
.primary-button-blue-sm-active {
  background: #3b3dbb 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.primary-button-blue-sm:active,
.primary-button-blue-sm:focus {
  background: #3b3dbb 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}

.primary-button-yellow-sm-active {
  background: #fcc55e 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.primary-button-yellow-sm:active,
.primary-button-yellow-sm:focus {
  background: #fcc55e 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.secondary-button-sm-active {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.secondary-button-sm:active,
.secondary-button-sm:focus {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
}
.primary-alert-blue-sm-hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}
.primary-alert-blue-sm:hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}

.primary-alert-yellow-sm-hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}
.primary-alert-yellow-sm:hover {
  box-shadow: 0px 8px 16px #23315580 !important;
}
.secondary-alert-sm-hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 8px 16px #23315580 !important;
}
.secondary-alert-sm:hover,
.tag-item:last-child:hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 8px 16px #23315580 !important;
}
.primary-alert-blue-sm-active {
  box-shadow: none !important;
}
.primary-alert-blue-sm:active,
.primary-alert-blue-sm:focus {
  box-shadow: none !important;
}
.primary-alert-yellow-sm-active {
  box-shadow: none !important;
}
.primary-alert-yellow-sm:active,
.primary-alert-yellow-sm:focus {
  box-shadow: none !important;
}
.secondary-alert-sm-active {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none !important;
}
.secondary-alert-sm:active,
.tag-item:last-child:active,
.tag-item:last-child:active,
.secondary-alert-sm:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none !important;
}*/
.gridContent {
    width: 68px;
    height: 100px;
    background: #0000001a 0% 0% no-repeat padding-box;
    opacity: 1;
}

.gridContainer {
    display: flex;
    justify-content: space-between;
    background: #00fff51a 0% 0% no-repeat padding-box;
}

.momentio-home-demo-video {
    width: 50%;
    position: absolute;
    margin-left: 0%;
    padding-top: 45px;
    z-index: 1;
}
.momentio-home-demo-video > video {
    max-width: 544px;
    width: 100%;
    height: 348px;
    cursor: pointer;
    font-family: "Raleway-Medium";
    box-shadow: 0px 8px 16px #3b3dbb40;
    border-radius: 24px;
    object-fit: cover;
    object-position: top;
}
.momentio-home-demo-link {
    max-width: 868px !important;
    width: 75%;
    height: 448px;
    background: var(--dayblue) 0% 0% no-repeat padding-box;
    background: #82c5dd 0% 0% no-repeat padding-box;
    border-radius: 24px;
    position: absolute;
    cursor: pointer;
    right: 0%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.momentio-home-tips {
    justify-content: space-between !important;
}
.momentio-blog-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / 20px
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-1-8);
    text-align: center;
    letter-spacing: 1.8px;
    color: #23315580;
}
.momentio-blog-title {
    margin-bottom: 15px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-36) / var(--unnamed-line-spacing-64)
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: center;
    letter-spacing: 0px;
    color: #233155;
}

.momentio-home-demo-container {
    display: flex;
    height: 500px;
    position: relative;
}
.momentio-file tbody .MuiTableCell-alignRight {
    max-width: 200px;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-35) var(--unnamed-font-family-raleway-medium);
    letter-spacing: 0px;
    color: #233155;
    padding: 12px 2px 12px 25px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.moment-video-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-64)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-1-8);
    text-align: left;
    letter-spacing: 1.8px;
    color: #ffffff80;
}
.moment-video-description {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-36) / var(--unnamed-line-spacing-50)
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
}

.moment-video-user {
    margin-top: 10px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    color: white;
    text-align: left;
    letter-spacing: 0px;
}

.moment-video-navigate {
    margin-top: 25px;
    width: 224px;
    height: 54px;
    border: 2px solid var(--white);
    border: 2px solid #ffffff;
    border-radius: 27px;
    background: transparent;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    outline: none !important;
    letter-spacing: 0px;
    color: #ffffff;
}

.momentio-home-tips-container {
    width: 30%;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 24px;
    max-width: 368px;
}

.momentio-home-tips-container > div > img {
    height: 220px;
    width: 100%;
    box-shadow: 0px 8px 16px #3b3dbb40;
    border-radius: 24px;
    opacity: 1;
}
.momentio-tips-content-container {
    padding: 28px 32px;
}
.momentio-get-start-text {
    color: var(--nightblue);
    text-align: left;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-21) var(--unnamed-font-family-raleway-medium);
    letter-spacing: 0px;
    color: #233155;
}
.momentio-tips-content-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-1-4);
    text-align: left;
    letter-spacing: 1.4px;
    color: #23315580;
}
.momentio-tips-content-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-24) / 36px
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: 0px;
    color: #233155;
}
.momentio-tips-content-desc {
    margin-top: 15px;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: 0px;

    color: #233155;
}

.momentio-blog-link {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-30)
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--sunsetblue);
    margin: 7px;
    text-align: center;
    cursor: pointer;
    margin-top: 24px;
}
.momentio-blog-link a {
    text-decoration: underline;
}
.momentio-cloud-bg {
    position: absolute;
    margin-top: -60px;
    width: inherit;
}

::-webkit-scrollbar {
    width: 10px;
    background: #233155;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #233155;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #233155;
}

.MuiDialog-paperWidthSm {
    max-width: 600px;
    border-radius: 24px;
}

.momentio-home-begin {
    margin: 64px 0;
    background: var(--sunsetblue) 0% 0% no-repeat padding-box;
    background: #3b3dbb 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 83.33%;
}
.momentio-home-begin-btn {
    width: 181px;
    margin-top: 30px;
    height: 54px;
    background: var(--sunyellow) 0% 0% no-repeat padding-box;
    background: #fcc55e 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: 0px;
    color: #1b3548;
}
.momentio-home-begin-link {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    cursor: pointer;
    text-align: center;
    letter-spacing: 0px;
    margin-top: 15px;
    color: #ffffff;
}
.momentio-home-begin-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-64)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-1-8);
    color: var(--white);
    text-align: center;
    color: #ffffff;
}
.momentio-home-begin-desc {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-48) / var(--unnamed-line-spacing-74)
        var(--unnamed-font-family-raleway-extraBold);
    padding: 0px 30px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: center;
}
.menu-button {
    display: flex;
    padding: 8px 13px;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
}
.menu-button-icon {
    background: url("../Images/mometio-menu-icon.svg") 0% 0% no-repeat
        padding-box;
    opacity: 1;
    display: inline-block;
    width: 27px;
    height: 18px;
    margin-top: 2px;
    margin-right: 7px;
}

.footer-separator {
    height: 50px;
    background: var(--backgroundlight) 0% 0% no-repeat padding-box;
    background: #f1f6f9 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
.momentio-footer-section {
    width: 25%;
    padding-right: 3%;
}
.momentio-footer-desc {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / 32px
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    text-decoration: underline;
    letter-spacing: 0px;
    color: #23315580;
    cursor: pointer;
}
.momentio-footer-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-24) / 29px
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: 0px;
    color: #233155;
}
.momentio-footer-company-copyright > span > a {
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-font-size-24)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: 0px;
    color: #ffffff;
}
.momentio-footer-company-copyright {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
}
.momentio-footer-company-name {
    width: 155px;
    margin-right: 35px;
    font: var(--unnamed-font-style-normal) normal 900 32px/44px Recoleta-Bold;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--white);
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
}
.momentio-footer-content {
    color: white;
    border: 1px solid #fff;
    position: relative;
    background: var(--nightblue) 0% 0% no-repeat padding-box;
    background: #233155 0% 0% no-repeat padding-box;
}

.momentio-footer-company {
    min-height: 70px;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}
.momentio-footer-company > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.momentio-home-demo-link > div {
    width: 66%;
    padding-right: 5px;
}

.momentio-home-tick {
    text-align: left;
    font: var(--unnamed-font-style-normal) normal 800
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-30)
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: 0px;
    color: #233155;
    display: flex;
}
.momentio-home-tick > span {
    width: 18px;
    background: url("../Images/momentio-tick-icon.svg") 0% 0% no-repeat;
    height: 13px;
    display: inline-block;
    margin-right: 10px;
    margin-top: 8px;
}

.header-container a,
.header-container a:-webkit-any-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none !important;
}

.header-menu-item .primary-button-yellow {
    padding: 11px 20px;
    cursor: pointer;
    height: auto;
    box-sizing: border-box;
}

.show-menu-icon {
    font-size: 24px !important;
    cursor: pointer;
}

.auth-content-subheader,
.auth-contact-subheader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
}

.sideMenu .MuiAccordionSummary-expandIcon.Mui-expanded {
    margin-right: -15px;
    transition: none;
}

button a:focus,
button a:hover {
    color: inherit;
    text-decoration: none;
}
.sideMenu .MuiSvgIcon-root,
.contactAccordion .MuiIconButton-edgeEnd,
.ewill
    .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd
    svg {
    height: 22px;
    width: 22px;
    color: #233155;
}
.side-menu-width {
    width: 310px;
}
.download-contact-button {
    margin: 15px 0 15px 24px;
}
.profile-container {
    padding: 32px;
    position: relative;
}

.momentio-ewill-value {
    width: 50%;
}
.momentio-my-safe-wrapper {
    display: flex;
    justify-content: space-between;
}
.MuiDialog-paperFullScreen {
    width: auto;
}

.MuiAutocomplete-option {
    margin: 0 !important;
}
.MuiAutocomplete-listbox {
    border: 1px solid #25325240 !important;
}

.MuiIconButton-root:hover {
    background: none !important;
}

.contactAccordion .MuiIconButton-edgeEnd svg.MuiSvgIcon-root {
    font-size: 2rem;
}

.MuiAutocomplete-listbox {
    margin: 0 !important;
}

.MuiTreeItem-root {
    width: 100%;
}

.message-grid {
    height: 160px;
    margin-bottom: 15px;
    background: var(--backgroundlight) 0% 0% no-repeat padding-box;
    background: #f1f6f9 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
}
.message-image-desc {
    padding: 24px;
}

.tree-component .MuiSvgIcon-root,
.media-select .MuiSvgIcon-root {
    outline: none;
    width: 32px !important;
    height: 32px !important;
}

.display-flex-media {
    display: flex;
}
.tree-component .MuiCheckbox-root,
.media-select .MuiCheckbox-root {
    color: #6bce90 !important;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    padding-left: 0 !important;
}
.subscription-container {
    padding: 32px 32px 15px 32px;
    margin-bottom: 15px;
    margin-top: 50px;
    min-height: 545px;
    flex-flow: column;
    justify-content: space-between;
}
.subscription-content-container {
    height: 375px;
}
.subscription-desc {
    align-items: baseline !important;
}
.subscription-title {
    margin-bottom: 20px;
}

.subscription-content {
    margin-bottom: 10px;
}
.MuiRadio-root:first-child {
    margin-left: -2px;
    padding-left: 0;
}

.tree-component .MuiCollapse-wrapperInner {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.MuiAutocomplete-paper {
    margin: 0px 0 !important;
    border-radius: 0 !important;
}

.MuiChip-labelSmall {
    font-size: 14px !important;
}

.MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
    font-size: 23px;
}

.display-flex {
    display: flex;
    align-items: center;
}

.add-contact .login-field-label,
.register-form .login-field-label {
    width: 40%;
    text-align: right;
    padding-right: 10px;
}
.add-contact .login-field-input,
.register-form .login-field-input {
    width: 60%;
}

.add-contact .mm-login-container,
.register-form .mm-form-container,
.register-form .mm-login-container {
    width: auto;
    padding: 0;
}

.register-form .mm-field-input-sm {
    max-width: 172px !important;
    width: 48%;
    min-width: 48%;
}

.register-form .mm-input-submit {
    text-align: left;
    margin-left: 40%;
    width: 60%;
}

.register-form .mm-form-links {
    margin-bottom: 20px;
}

.ReactFlagsSelect-module_selectValue__152eS {
    pointer-events: none;
    display: flex;
    align-items: center;
    width: 92%;
    padding-left: 0 !important;
    overflow: hidden;
}

.momentio-dashboard-container {
    display: flex;
    padding-top: 70px;
    margin-top: 70px;
    background: transparent
        linear-gradient(180deg, var(--backgroundmedium) 0%, #f2f6f9 100%) 0% 0%
        no-repeat padding-box;
}
.MuiFormGroup-root svg.MuiSvgIcon-root {
    width: 26px;
    height: 26px;
}

.momentio-text-normal.contact-profile-icon svg {
    width: 24px;
    height: 24px;
}
.display-flex-center {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.recieved-messages .add-new-attachment {
    text-decoration: underline !important;
    padding: 0 10px;
}

.add-new-attachment {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-18) / 24px
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--sunsetblue);
    text-align: right;
    cursor: pointer;
}

.dashboard-overview-container {
    padding: 23px 30px 23px 23px;
    background: var(--backgroundlight) 0% 0% no-repeat padding-box;
    background: #f1f6f9 0% 0% no-repeat padding-box;
    border-radius: 24px;
}

.add-new-suggestions {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-18) / 24px
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--sunsetblue);
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 5px;
}

.shared-file {
    display: inline-block;
    outline: none !important;
    border: 2px solid #253252;
    border-radius: 27px;
    opacity: 1;
    width: 44px;
    height: 44px;
    align-items: center;
    height: 44px;
    text-align: center;
    padding-top: 4px;
    margin-right: 2px;
}

.MuiChip-root {
    height: 44px !important;
    max-width: 200px !important;
}

.MuiChip-label {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.add-new-attachment svg {
    font-size: 21px;
    margin-left: 3px;
}

.MuiSvgIcon-root {
    width: 22px;
    height: 22px;
}

.ewill-secondary-alert-sm svg {
    width: 22px !important;
    height: 22px !important;
}
.language-select button .ReactFlagsSelect-module_label__27pw9 {
    display: none;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
    display: none !important;
}

.language-select .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: none !important;
    opacity: 1 !important;
    width: 60px;
    background: none !important;
}

.language-select
    .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after,
.language-select .ReactFlagsSelect-module_selectBtn__19wW7:after,
.language-select
    .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
}
.language-select .ReactFlagsSelect-module_flagsSelect__2pfa2 ul {
    font-size: 16px;
    position: absolute;
    right: 300px;
    margin-left: -100px;
    min-width: 150px;
}
.menu-options {
    padding-top: 15px;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #3b3dbb40;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    border-bottom: 1px solid #23315540;
    padding: 10px 16px;
    letter-spacing: 0px;
    color: #233155;
    opacity: 1;
}
.menu-wrapper {
    display: flex;
    justify-content: flex-end;
}
.menu-container {
    position: absolute;
    width: 180px;
    border-radius: 4px;
    opacity: 1;
}
.alerts-container {
    position: absolute;
    width: 300px;
    border-radius: 4px;
    opacity: 1;
}
.tree-component {
    padding: 10px;
}
.language-select {
    width: 35px;
}
.language-select .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    margin-top: 0;
}
.language-select li span.ReactFlagsSelect-module_selectFlag__2q5gC {
    display: none;
}

.language-select .ReactFlagsSelect-module_selectBtn__19wW7::after {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
}

.language-select .ReactFlagsSelect-module_selectBtn__19wW7:focus {
    border: none !important;
    opacity: 1 !important;
}

.momentio-mysafe-container {
    width: 32%;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 24px;
    position: relative;
    /* max-width: 368px; */
}

.MuiDialog-paperWidthSm {
    max-width: 600px;
    border-radius: 24px !important;
}

.MuiDialog-scrollPaper {
    align-items: unset !important;
    height: calc(100%) !important;
    max-height: calc(100%) !important;
    overflow-y: auto;
}

.MuiDialog-paperScrollPaper {
    width: auto !important;
    max-height: unset !important;
    overflow: unset !important;
    height: fit-content !important;
    height: -moz-fit-content !important;
}

select {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    cursor: pointer;
    background-position-x: 90%;
    background-repeat: no-repeat;
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-size: 11px;
    background-position: calc(100% - 0.7em) center;
}
.momentio-progress-circle {
    width: 50px;
    height: 50px;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    display: inline-block;
    position: relative;
    background: #eee;
    text-align: center;
    line-height: 50px;
    margin: 8px 20px 0 0;
}

.momentio-progress-circle .overlay {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #eee;
}

.momentio-progress-circle .left,
.momentio-progress-circle .right {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border: 25px solid #6bce90;
    border-radius: 25px 0px 0px 25px;
    border-right: 0;
    opacity: 1 !important;
    transform-origin: right;
}

.momentio-progress-circle .left {
    animation: load1 1s linear forwards;
}

.momentio-progress-circle:nth-of-type(2) .right,
.momentio-progress-circle:nth-of-type(3) .right {
    animation: load2 0.5s linear forwards 1s;
}

.momentio-progress-circle:last-of-type .right,
.momentio-progress-circle:first-of-type .right {
    animation: load3 0.8s linear forwards 1s;
}

@property --p {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

.pie {
    --p: 20;
    --b: 22px;
    --c: #6bce90;
    --w: 50px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    margin: 5px;
    place-content: center;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    border-radius: 50%;
    background: #eee;
    font-family: sans-serif;
    margin: 8px 20px 0 0;
}
.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}
.pie:before {
    inset: 0;
    background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
            var(--b) no-repeat,
        conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
}
.pie:after {
    transform: rotate(calc(var(--p) * 3.6deg));
}
.animate {
    animation: p 1s 0.5s both;
}
.no-round:before {
    background-size: 0 0, auto;
}
.no-round:after {
    content: none;
}
.dahsboard-progress {
    display: flex;
    padding: 14px 0;
    justify-content: space-between;
    border-bottom: 1px solid #23315540;
}
.dahsboard-progress-last {
    display: flex;
    padding: 14px 0;
    justify-content: space-between;
}
.w3-border {
    background: #dde3e9 0% 0% no-repeat padding-box;
    border-radius: 2px;
    position: relative;
    width: 100px;
    margin-left: 10px;
}
.w3-grey {
    height: 20px;
    background: #6bce90;
    border-radius: 2px;
}

.register-consent {
    margin-left: 40%;
}
.contact-heir {
    height: 60px;
    display: flex;
    align-items: end;
}

.contact-heir svg {
    width: 22px;
    height: 22px;
}

.register-form .MuiSvgIcon-root {
    width: 32px !important;
    height: 32px !important;
}

.MuiIconButton-label input {
    display: none;
}

.MuiDialog-paper .mm-login-container {
    background: none !important;
    box-shadow: none !important;
}

.margin-sm-right {
    margin-right: 5px !important;
}

.contact-delete {
    text-align: center;
}

.showXS {
    display: none;
}

.showSM {
    display: none;
}

.message-grid > div > img,
.message-grid > div > svg {
    height: 160px;
    width: 160px;
    border-radius: 8px;
}

.edit-message-icon {
    display: flex;
    align-items: center;
    position: relative;
    right: unset;
    margin-top: 10px;
}

.edit-message-icon .momentio-text-normal {
    visibility: hidden;
}

.edit-message-icon .secondary-alert-sm {
    position: relative !important;
    left: 0 !important;
    right: unset;
}

.menu-item-sm {
    display: none;
}

.messagePreview .messages {
    padding: 60px 80px 5px 80px;
}
.messagePreview .n_paragraph {
    display: flex;
    text-align: center;
    justify-content: center;
}
.messagePreview .content-one {
    text-align: center;
    margin-bottom: 0 !important;
}
.messagePreview .header24Medium {
    padding: 60px 0;
}
.messagePreview .messageDate {
    margin-right: 5px;
}
.messagePreview .momentio-tips-content-container {
    justify-content: center;
    padding: 60px 74px;
    text-align: center;
}
.messagePreview .sunset-blue-tile div {
    text-align: center;
}
.messagePreview .sunset-blue-tile {
    border-radius: 24px;
}

.messagePreview .momentio-home-tick {
    display: block !important;
}
.subscription-frequent-header {
    padding-left: 32px;
    height: 50px;
    /* UI Properties */
    background: var(--sunyellow) 0% 0% no-repeat padding-box;
    background: #fcc55e 0% 0% no-repeat padding-box;
    border-radius: 24px 24px 0px 0px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-64)
        var(--unnamed-font-family-raleway);
    letter-spacing: var(--unnamed-character-spacing-1-8);
    color: var(--nightblue);
    text-align: left;
}

.subscription .momentio-home-tick {
    margin-bottom: 8px;
    align-items: center;
}

.subscription .momentio-home-tick > span {
    display: block !important;
    margin-top: 0 !important;
}
.momentio-dashboard-grid > .MuiGrid-item {
    margin-bottom: 15px;
}
.momentio-dashboard-grid > .MuiGrid-item:nth-child(2){
    padding-right: 10px;
}
.momentio-dashboard-grid > .MuiGrid-item:nth-child(3){
    padding-left: 10px;
}
.add-tag-btn {
    text-align: center;
    width: 140px;
    margin-left: 14px;
}
.message-delivery-container input {
    width: 100% !important;
    min-width: unset !important;
    outline: none !important;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.upgrade-subscription-btn {
    text-align: center;
    position: absolute;
    right: 45px;
    top: 85px;
}
.preview-back-icon {
    position: absolute;
    right: 0;
}
.preview-back-icon svg{
    height: 22px;
    width: 22px;
}
.filter-dropdown {
    margin: 0px 24px 0px 0px !important;
    width: 180px !important;
    min-width: 180px !important;
}
@media only screen and (max-width: 1000px) {
    .header-menu-item {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-18)
            var(--unnamed-font-family-raleway-bold) !important;
    }
}

@media only screen and (max-width: 1279px) {
    .momentio-dashboard-grid > .MuiGrid-item:nth-child(2){
        padding-right: 0;
    }
    .momentio-dashboard-grid > .MuiGrid-item:nth-child(3){
        padding-left: 0;
    }
    .subscription-container {
        min-height: unset !important;
    }
    .subscription-content-container {
        height: unset !important;
    }
}

@media only screen and (max-width: 1300px) {
    .moment-video-description {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) 28px / var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-extraBold);
        letter-spacing: var(--unnamed-character-spacing-0);
    }
    .momentio-footer-container {
        width: 100%;
    }
    .momentio-home-demo-link {
        width: 66%;
    }
    .momentio-home-demo-video {
        width: 40%;
    }
    .momentio-home-demo-link > div {
        width: 80%;
    }
    .image-xl {
        display: none !important;
    }
    .content-grid-50 {
        width: 75% !important;
    }
    .width75 {
        width: 75% !important;
    }
    .content-grid-50-sm {
        width: 25% !important;
    }
    .header-title-section {
        width: 20%;
    }
    .header-menu-section {
        width: 80% !important;
    }
}
@media only screen and (max-width: 1276px) {
    .momentio-mysafe-container {
        height: inherit;
        margin-bottom: 25px;
        width: 100% !important;
    }
    .momentio-my-safe-wrapper {
        flex-direction: column;
    }
}
@media only screen and (max-width: 1621px) {
    .auth-content .content-one {
        margin-bottom: 14px;
    }
    .auth-content-subheader,
    .auth-contact-subheader {
        margin-bottom: 24px;
    }
    .momentio-blog-link {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-extraBold);
    }
    .content-one {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-40) /
            var(--unnamed-line-spacing-74)
            var(--unnamed-font-family-raleway-extraBold);
    }
    .momentio-home-begin-desc {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-40) /
            var(--unnamed-line-spacing-74)
            var(--unnamed-font-family-raleway-extraBold);
    }
    .n_header-2 {
        font-size: var(--unnamed-font-size-30);
    }

    .text2 {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-30) /
            var(--unnamed-line-spacing-50)
            var(--unnamed-font-family-raleway-extraBold);
    }

    .momentio-blog-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-30) /
            var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-extraBold);
    }

    .moment-video-description {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-30) /
            var(--unnamed-line-spacing-50)
            var(--unnamed-font-family-raleway-extraBold);
    }
    .n_header-3 {
        font-size: var(--unnamed-font-size-21);
    }
    .n_intro {
        font-size: var(--unnamed-font-size-21);
    }
    .text3 {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-21) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-medium);
    }
    .dashboard-description {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-21) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-medium);
    }
    .momentio-tips-content-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-21) / 36px
            var(--unnamed-font-family-raleway-extraBold);
    }
    .momentio-footer-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-21) / 29px
            var(--unnamed-font-family-raleway-extraBold);
    }
    .n_header-4 {
        font-size: var(--unnamed-font-size-16);
    }
    .n_paragraph {
        font-size: var(--unnamed-font-size-16);
    }
    .header-menu-item {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-21)
            var(--unnamed-font-family-raleway-bold);
    }
    
    .text1 {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-semiBold);
    }
    .contacts-content {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-bold);
    }
    .contact-summary-header {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-bold);
    }
    .contacts-add-link {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-semiBold);
    }
    .contacts-headers {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    }
    
    .primary-button-red {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    
    }
    .primary-button-blue {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .secondary-button {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .primary-button-yellow {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .momentio-blog-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / 20px
            var(--unnamed-font-family-raleway-semiBold);
    }
    .momentio-file tbody .MuiTableCell-alignRight {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-35) var(--unnamed-font-family-raleway-medium);
    }
    
    .moment-video-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-semiBold);
    }
    
    .moment-video-user {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-semiBold);
    }
    
    .moment-video-navigate {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .momentio-get-start-text {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-21) var(--unnamed-font-family-raleway-medium);
    }
    .momentio-tips-content-desc {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    }
    .momentio-home-begin-btn {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .momentio-home-begin-title {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-semiBold);
    }
    .momentio-footer-desc {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / 32px
            var(--unnamed-font-family-raleway-semiBold);
    }
    .momentio-footer-company-copyright > span > a {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / var(--unnamed-font-size-24)
            var(--unnamed-font-family-raleway-semiBold);
    }
    .momentio-footer-company-copyright {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    }
    .momentio-home-tick {
        font: var(--unnamed-font-style-normal) normal 800
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-extraBold);
    }
    .add-new-attachment {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
            var(--unnamed-font-size-16) / 24px var(--unnamed-font-family-raleway-extraBold);
    }
    
    .add-new-suggestions {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
            var(--unnamed-font-size-16) / 24px var(--unnamed-font-family-raleway-extraBold);
    
    }
    .menu-options {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    
    }
    .subscription-frequent-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway);
    }
    .n_caption {
        font-size: var(--unnamed-font-size-12);
    }
    .contacts-table-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-12) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium) !important;
    }
    .momentio-tips-content-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-12) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-semiBold);
    }
}

@media only screen and (max-width: 1215px) {
    .download-contact-button {
        margin: 0px 0 15px 0px;
        width: fit-content;
        width: -moz-fit-content !important;
    }
    .notification-buttons {
        flex-direction: column;
    }
}
@media only screen and (max-width: 1024px) {
    .MuiDrawer-root .MuiDrawer-paper {
        width: 100%;
    }
    .edit-message-icon .momentio-text-normal {
        visibility: visible !important;
    }
    ::-webkit-scrollbar {
        width: 0px !important;
        background: #233155;
    }
    ::-webkit-scrollbar-thumb {
        background: #233155;
        border-radius: 0 !important;
    }
    .ewill .MuiAccordionDetails-root {
        padding: 20px 32px 32px 75px;
    }
    .ewill .secondary-alert-sm {
        margin-left: 0 !important;
        position: relative !important;
        margin-bottom: 5px;
        right: 0;
    }
    .momentio-my-safe-wrapper {
        flex-direction: column;
    }
    .header-menu-section-sm {
        display: flex;
    }
    .download-contact-button {
        margin: 15px 0 15px 0px;
    }
    .personalOverview {
        width: 98%;
    }
    .side-menu-width {
        width: 100%;
    }
    .momentio-dashboard-container {
        padding-top: 24px;
    }

    .ewill .task-completed,
    .ewill .task-not-completed {
        margin: -4px 8px -4px 0px !important;
    }

    .ewill .accordion-content-option-label {
        width: 40%;
    }

    .momentio-mysafe-container {
        height: inherit;
        margin-bottom: 25px;
        width: 100% !important;
    }
    .menu-item-sm {
        display: flex !important;
    }
    .header-menu-section {
        display: none;
    }
    .side-menu-hidden-header {
        display: none;
    }
    .header-title-section {
        justify-content: space-between !important;
        width: 100% !important;
        position: relative;
    }
    .side-menu-header {
        position: absolute;
        height: auto;
        min-height: unset !important;
        width: 100% !important;
        z-index: 109;
        right: 0;
        top: 70px;
        padding: 0 28px 25px 25px;
        background: #c3daea;
    }
    .show-flex-sm {
        display: block;
    }
    .show-flex-lg {
        display: none;
    }
    .sideMenu .MuiAccordionSummary-root {
        padding: 0px 16px 0px 0px;
    }
    .auth-content {
        width: 100% !important;
        min-height: calc(100vh - 260px) !important;
        padding: 0 32px;
    }
}

@media only screen and (max-width: 960px) {
    .showSM {
        display: flex;
    }
    .add-tag-btn {
        margin-left: 0;
        margin-top: 15px;
    }
    .hideSM {
        display: none;
    }
    .auth-content-subheader,
    .auth-contact-subheader {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }
    .momentio-home-demo-link {
        padding: 0;
        width: 60%;
    }
    .momentio-home-demo-video {
        width: 48%;
        height: 381px;
        margin-left: 0;
        padding-top: 70px;
    }
    .momentio-home-demo-video > video {
        height: 300px;
    }

    .footer-separator {
        height: 22px;
        width: 100%;
        margin-top: -2px;
        position: absolute;
        z-index: 11;
    }
    .footer-separator-top {
        position: relative !important;
        margin-top: 0 !important;
    }
    .momentio-footer-company div {
        margin-bottom: 10px;
    }
    .momentio-footer-company > div {
        align-items: baseline !important;
        justify-content: flex-start !important;
        flex-direction: column;
    }
    .momentio-blog-link {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-14)/19px
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .text3 {
        padding: 0 !important;
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-medium) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        color: var(--nightblue) !important;
    }
    .text1 {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-semiBold) !important;
        letter-spacing: var(--unnamed-character-spacing-1-4) !important;
        letter-spacing: 1.4px !important;
    }
    .text2 {
        padding: 0 !important;
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-24) /
            var(--unnamed-line-spacing-50)
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .momentio-footer-company-copyright > span > a {
        display: block !important;
        margin-left: 0;
        margin-top: 15px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-14) / var(--unnamed-font-size-24)
            var(--unnamed-font-family-raleway-semiBold) !important;
    }
    .momentio-footer-company-copyright {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .momentio-footer-company-name {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-24) / 33px Recoleta-Bold !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        margin-top: 15px;
    }
    .momentio-footer-company {
        min-height: 260px !important;
        align-items: baseline !important;
        padding: 20px 4% 0px 4% !important;
        margin-top: 35px !important;
        box-sizing: border-box;
    }
    .momentio-footer-section {
        width: 100% !important;
        margin-bottom: 25px !important;
    }

    .momentio-home-begin-link {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        color: var(--white) !important;
        text-align: center !important;
        text-decoration: underline !important;
        cursor: pointer;
    }
    .momentio-home-tick {
        text-align: left;
        font: var(--unnamed-font-style-normal) normal 800
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-extraBold);
        letter-spacing: 0px;
        color: #233155;
    }

    .momentio-home-begin-btn {
        font: var(--unnamed-font-style-normal) normal bold 16px /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        text-align: center !important;
    }

    .momentio-home-begin-desc {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-32) /
            var(--unnamed-line-spacing-40)
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .momentio-home-begin-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-semiBold) !important;
        letter-spacing: var(--unnamed-character-spacing-1-4) !important;
    }

    .momentio-tips-content-desc {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }

    .momentio-tips-content-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-18)/28px
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .momentio-tips-content-header {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-semiBold) !important;
    }
    .momentio-blog-header {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-semiBold) !important;
        letter-spacing: var(--unnamed-character-spacing-1-4) !important;
    }
    .momentio-blog-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-24) / var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .moment-video-header {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-semiBold) !important;
        letter-spacing: var(--unnamed-character-spacing-1-4) !important;
    }
    .moment-video-description {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-24) / var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .moment-video-user {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-semiBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
    }
    .momentioHome .momentio-home-tips-container:nth-child(1),
    .momentioHome .momentio-home-tips-container:nth-child(2) {
        display: none !important;
    }
    .momentio-home-tips-container > div > img {
        height: 205px !important;
    }

    .primary-button-blue {
        font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        letter-spacing: 0px !important;
    }

    .momentio-get-start-text {
        font: normal normal 500 16px/19px Raleway !important;
    }

    .content-one {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-32)/35px
            var(--unnamed-font-family-raleway-extraBold) !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        color: var(--nightblue) !important;
        margin-bottom: 10px;
    }

    .header-menu-item {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-18) / var(--unnamed-line-spacing-21)
            var(--unnamed-font-family-raleway-bold);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--nightblue);
        text-align: left;
        letter-spacing: 0px;
        color: #233155;
    }

    .header-title-item {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-24) / 33px Recoleta-Bold !important;
        letter-spacing: var(--unnamed-character-spacing-0) !important;
        color: var(--nightblue) !important;
        text-align: left !important;
    }

    .image-xl {
        display: none !important;
    }
    .text4 {
        margin-top: 40px;
        justify-content: center !important;
        flex-flow: column;
    }
    .momentioHome .momentio-home-tips {
        flex-direction: unset !important;
    }
    .momentio-mysafe-container {
        height: inherit;
        margin-bottom: 25px;
        width: 100% !important;
    }
    .text5 {
        margin-top: 40px;
        width: 100% !important;
    }
    .momentio-home-tips-container {
        width: 100% !important;
        margin-bottom: 30px !important;
    }
    
    .n_header-2 {
        font-size: var(--unnamed-font-size-24);
    }

    .momentio-blog-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-24) /
            var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway-extraBold);
    }

    .n_header-3 {
        font-size: var(--unnamed-font-size-18);
    }
    .n_intro {
        font-size: var(--unnamed-font-size-18);
    }
    .dashboard-description {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-medium);
    }
    .momentio-footer-title {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-800) var(--unnamed-font-size-18) / 29px
            var(--unnamed-font-family-raleway-extraBold);
    }
    .n_header-4 {
        font-size: var(--unnamed-font-size-14);
    }
    .n_paragraph {
        font-size: var(--unnamed-font-size-14);
    }

    .contacts-content {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-bold);
    }
    .contact-summary-header {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-bold);
    }
    .contacts-add-link {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-semiBold);
    }
    .contacts-headers {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    }
    
    .primary-button-red {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    
    }

    .secondary-button {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .primary-button-yellow {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .momentio-blog-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-14) / 20px
            var(--unnamed-font-family-raleway-semiBold);
    }
    .momentio-file tbody .MuiTableCell-alignRight {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-35) var(--unnamed-font-family-raleway-medium);
    }
    
    
    .moment-video-navigate {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    .momentio-get-start-text {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-21) var(--unnamed-font-family-raleway-medium);
    }

    .momentio-footer-desc {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-14) / 32px
            var(--unnamed-font-family-raleway-semiBold);
    }


    .add-new-attachment {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
            var(--unnamed-font-size-14) / 24px var(--unnamed-font-family-raleway-extraBold);
    }
    
    .add-new-suggestions {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
            var(--unnamed-font-size-14) / 24px var(--unnamed-font-family-raleway-extraBold);
    
    }
    .menu-options {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    
    }
    .subscription-frequent-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-64)
            var(--unnamed-font-family-raleway);
    }

    .n_caption {
        font-size: var(--unnamed-font-size-10);
    }
    .contacts-table-header {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
            var(--unnamed-font-size-10) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium) !important;
    }

}
@media only screen and (max-width: 768px) {
    .message-grid {
        height: auto !important;
        margin-bottom: 8px;
    }

    .message-image-container {
        display: flex;
        align-items: center;
    }

    .message-grid > div > img,
    .message-grid > div > svg {
        height: 70px;
        width: 70px;
    }
    .message-image-desc {
        padding: 14px;
    }
    .dashboard-description {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
            var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-medium);
    }
    .n_header-2 {
        font-size: 30px;
        line-height: 44px;
    }
    .momentio-cloud-bg {
        margin-top: 0px;
    }
    .container {
        width: 100%;
    }
    .momentio-home-demo-link {
        width: 100% !important;
        padding-top: 80px !important;
        margin-top: 20px;
        height: auto !important;
        right: unset !important;
        justify-content: unset !important;
        align-items: flex-end !important;
        position: relative !important;
        padding-right: 0 !important;
    }
    .momentio-home-demo-link > div {
        width: 100% !important;
        padding: 20px !important;
    }
    .momentio-home-demo-video > video {
        height: 342px !important;
    }
    .playpause {
        margin-left: 0% !important;
        margin-top: 20% !important;
    }
    .momentio-home-demo-video {
        margin-left: 0 !important;
        width: 100% !important;
        padding-top: 0 !important;
        position: relative !important;
        height: 230px !important;
        display: flex;
        justify-content: center;
    }
    .momentio-home-demo-container {
        display: block !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 700px) {
    .content-grid-50-sm {
        display: none !important;
    }
    .content-grid-50 {
        width: 100% !important;
    }
    .auth-contact-subheader {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }
    .side-menu-header {
        padding: 0px 10px 25px 6px;
    }
}

@media only screen and (max-width: 568px) {
    .upgrade-subscription-btn {
        position: initial;
    }
    .messagePreview .content-one {
        text-align: left;
        margin-bottom: 0 !important;
    }

    .messagePreview .header24Medium {
        padding: 24px 0;
    }

    .messagePreview .header-title-section-login {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-24) / 33px Recoleta-Bold;
        display: block !important;
    }
    .messagePreview .messages {
        padding: 24px;
    }
    .messagePreview .n_paragraph {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/24px
            var(--unnamed-font-family-raleway);
        text-align: left;
        display: block !important;
    }
    .momentio-text-normal {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium) !important;
    }
    .momentio-text-bold {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold) !important;
    }

    .showXS {
        display: block;
    }
    .hideXS {
        display: none !important;
    }
    .login-field-input-xs input {
        min-width: 72px !important;
    }

    .add-contact .mm-form-container,
    .messages {
        width: auto !important;
    }

    .contact-delete {
        text-align: center !important;
        margin-left: unset !important;
    }
    .ewill .overviewContainer {
        padding: 15px !important;
    }
    .input-container-column {
        padding: 0 !important;
        flex-direction: column;
        padding-bottom: 15px !important;
        align-items: flex-start !important;
    }
    .headerAttachment {
        margin-top: 0 !important;
    }

    .auth-header-container {
        padding: 0 15px;
    }
    .margin-sm-top {
        margin-top: 10px;
        margin-left: 0 !important;
    }
    .personalOverview {
        width: 100%;
    }

    .ewill-secondary-alert-sm {
        width: 34px;
        height: 34px;
    }
    .ewill .accordion-content-option-label {
        width: 100%;
    }
    .momentio-ewill-value {
        width: 100%;
    }
    .header24Medium{
        font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-35) var(--unnamed-font-family-raleway-medium);
    }
    .primary-button-yellow {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-medium) !important;
    }
    .ewill input,
    .ewill textarea,
    .ewill select,
    .ewill .MuiInputBase-root.MuiInput-root.MuiInput-underline,
    .ewill .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        width: 100% !important;
    }
    .messages .MuiAccordionDetails-root {
        padding: 25px 15px 25px !important;
    }
    .ewill .MuiAccordionDetails-root {
        padding: 26px 20px 20px 20px;
    }
    .ewill .MuiAccordionSummary-root {
        padding: 0 26px 0 16px !important;
    }
    .contacts .MuiAccordionSummary-root {
        padding: 0px 10px 0px 15px !important;
    }
    .auth-content {
        padding: 0 15px;
        margin-bottom: 0 !important;
        min-height: calc(100vh - 361px) !important;
        padding: 0 !important;
    }
    .flex-direction-column {
        flex-direction: column;
    }

    .add-contact .mm-login-container,
    .register-form .mm-form-container,
    .register-form .mm-login-container {
        width: 424px;
    }

    .display-flex {
        display: block;
    }

    .add-contact .login-field-label,
    .register-form .login-field-label {
        width: unset;
        text-align: unset;
        padding-right: 10px;
    }
    .add-contact .form-field .login-field-label {
        width: unset;
        text-align: unset;
        padding-right: 10px;
        position: absolute;
        top: 0;
        margin-top: 6px;
        left: 11px;
        background: #fff;
        padding: 0 5px;
    }
    .add-contact .form-field .language-field-label {
        margin-top: -10px ;
        background: #fff;
        z-index: 1;
    }
    .add-contact .language-form-field {
        margin-top: 25px;
    }    
    .add-contact .form-field .contact-heir {
        height: 30px;
    }

    .add-contact .form-field .heir-field-label {
        padding-left: 15px;
    }
    
    .add-contact .login-field-input,
    .register-form .login-field-input {
        width: unset;
    }

    .password-error {
        width: unset !important;
        margin-top: 15px;
    }

    .mm-form-declaration {
        margin-left: unset !important;
        margin-top: 15px;
    }

    .register-form .mm-input-submit {
        text-align: center;
        margin-left: unset;
        width: unset;
    }

    .add-contact .login-field-input,
    .register-form .login-field-input {
        width: unset;
    }

    .register-consent {
        margin-left: unset;
    }
    .container {
        width: 100%;
    }
    .mm-field-input-sm {
        width: 100% !important;
    }
    .content-grid-50 {
        width: 100% !important;
    }
    .register-form .mm-field-input-sm {
        max-width: unset !important;
    }
    .mm-input-submit button {
        width: 100% !important;
    }
    .MuiFormGroup-root svg.MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
    }
    .mm-field-input {
        box-sizing: border-box !important;
        width: 100% !important;
    }
    .login-field-input {
        display: block !important;
    }
    .message-delivery-container > .login-field-input {
        display: flex !important;
    }

    .login-field-input input,
    .login-field-input select,
    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        width: 100% !important;
    }
    .mm-login-header {
        font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-20) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway-bold) !important;
    }
    .ewill .task-completed,
    .ewill .task-not-completed {
        font-size: 24px;
    }

    .login-field-label {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-21)
            var(--unnamed-font-family-raleway-bold);
    }
    .login-field-input input,
    .login-field-input select,
    .ReactFlagsSelect-module_flagsSelect__2pfa2,
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1,
    .login-field-input-text,
    .MuiAutocomplete-noOptions,
    .MuiAutocomplete-option,
    .MuiChip-label {
        font: var(--unnamed-font-style-normal) normal normal
        var(--unnamed-font-size-14) / var(--unnamed-line-spacing-21)
        var(--unnamed-font-family-raleway-medium);
    }
    .message-delivery-container input {
        width: 100% !important;
        min-width: unset !important;
        outline: none !important;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--nightblue);
    }
    .header-menu-item .primary-button-register {
        padding: 11px 5px !important;
        width: 130px !important;
    }
    .mm-login-container {
        width: auto !important;
        padding: 15px !important;
    }
    .image-section-1 {
        width: 50%;
    }
    .image-section-2 {
        display: none !important;
    }
    .text2 {
        padding: 0 !important;
    }
    .text3 {
        padding: 0 !important;
    }
    .files-container-column {
        padding: 0 !important;
        padding-bottom: 5px !important;
    }
    .files-container-column .login-field-input {
        display: flex !important;
    }
    .files-container-column select {
        min-width: 160px !important;
    }
    .momentio-footer-company {
        margin-top: 0px !important;
    }
    .contacts .MuiAccordionSummary-content p:first-child {
        width: 55% !important;
        max-width: 55% !important;
    }
    .contacts .MuiAccordionSummary-content p,
    .messages .MuiAccordionSummary-content p {
        width: 15% !important;
    }

    .auth-content-subheader,
    .auth-contact-subheader,
    .auth-dashboard-subheader {
        padding: 0 15px !important;
    }
    .header-sm {
        width: 100% !important;
    }
    .header-container-sm .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .message-title-sm {
        padding-bottom: 0 !important;
    }
    .message-delivery-sm {
        padding-top: 0 !important;
    }
}

@keyframes load1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes load2 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(270deg);
    }
}

@keyframes load3 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(300deg);
    }
}

@media only screen and (max-width: 389px) {
    .dashboard-percentage .dahsboard-progress {
        flex-direction: column;
    }
    .dashboard-percentage .dahsboard-progress-last {
        flex-direction: column;
    }
    
}
@media only screen and (max-width: 376px) {
    .momentio-home-demo-video > video {
        height: 221px !important;
    }
    .momentio-home-demo-link {
        padding-top: 10px !important;
        margin-top: -30px;
    }
    .auth-header-container {
        padding: 0px 15px;
    }
}
@media only screen and (max-width: 359px) {
    .image-section-1 {
        display: none !important;
    }
    .image-container {
        justify-content: center;
    }
}
