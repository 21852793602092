@import "style.css";

.mm-login-container {
    padding: 32px;
    width: 424px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #3b3dbb40;
    border-radius: 24px;
    box-sizing: border-box;
}

.mm-login-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800)
        var(--unnamed-font-size-24) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway-extraBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    opacity: 1;
    box-sizing: border-box;
}

.momentio-text-normal.accordion-content-option-label {
    word-break: break-word;
}

.header24Medium {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-24) /
        var(--unnamed-line-spacing-35) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    opacity: 1;
    box-sizing: border-box;
}

.login-field-label {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    opacity: 1;

    box-sizing: border-box;
}
.login-field-label-height {
    margin-top: 20px;
}
select option {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}

.node.leaf {
    display: block !important;
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid #23315540;
}
textarea {
    resize: none;
}
.login-field-input .MuiInputBase-root.MuiInput-root.MuiInput-underline {
    width: auto !important;
}
.MuiSelect-select:focus {
    border-radius: 0;
    background: none !important;
}

.MuiInput-underline:before {
    border: none !important;
}

.MuiInput-underline:after {
    border: none !important;
}

.login-field-input input,
.login-field-input textarea,
.login-field-input select,
.login-field-input .MuiInputBase-root.MuiInput-root.MuiInput-underline,
.ReactFlagsSelect-module_flagsSelect__2pfa2 {
    width: 100%;
    min-width: 200px;
    height: 54px;
    border: 1px solid #25325240;
    border-radius: 8px;
    outline: none !important;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    padding: 10px 16px;
    box-sizing: border-box;
    margin-top: 16px;
    box-sizing: border-box;
}
.login-field-input-xs input {
    width: 20% !important;
    min-width: 110px;
}
.add-message .login-field-input {
    width: 100%;
}

.add-message .login-field-label {
    width: 100% !important;
    text-align: left !important;
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 120px;
    }
}
li {
    list-style: none;
    margin: 5px;
    display: inline-block;
}

div#panel-moment-header {
    padding-bottom: 14px;
    padding-top: 14px;
    box-sizing: border-box;
    border-color: transparent !important;
}
.header-menu-section-login {
    display: flex !important;
}
.login-field-input input:focus,
.login-field-input textarea:focus,
.login-field-input select:focus,
.login-field-input .MuiInputBase-root.MuiInput-root.MuiInput-underline:focus,
.ReactFlagsSelect-module_selectBtn__19wW7:focus {
    border: 1px solid #3b3dbb !important;
    opacity: 1 !important;
}

.login-field-input .ReactFlagsSelect-module_flagsSelect__2pfa2 ul {
    border-radius: 0 !important;
    margin-top: 0;
    padding: 0;
    border-color: transparent;
    outline: none !important;
    box-shadow: 0px 2px 3px #233155;
    overflow-x: hidden !important;
}
.ReactFlagsSelect-module_flagsSelect__2pfa2 ul {
    border-radius: 8px;
    margin-top: 0;
    padding: 0;
    border-color: transparent;
    outline: none !important;
    box-shadow: 0px 2px 3px #233155;
}

.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    display: none !important;
}

.ReactFlagsSelect-module_filterBox__3m8EU input {
    min-width: unset !important;
    width: calc(100% - 20px) !important;
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
    padding: 4px 15px;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 ul li {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    height: 44px;
    display: flex;
    margin: 0;
    align-items: center;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 ul li:hover {
    background: #2331551a 0% 0% no-repeat padding-box;
}
.momention-menu-icon {
    background: none;
    outline: none;
    border: none;
    position: absolute;
    right: 45px;
    height: 64px;
    cursor: pointer;
}
.momentio-text-normal {
    text-align: left;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-30) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.momentio-text-bold {
    text-align: left;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-30)
        var(--unnamed-font-family-raleway-bold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
    position: relative;
    vertical-align: inherit;
    padding-bottom: 5px;
    text-align: left;
    border: 0;
    opacity: 1;
    padding: 0;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
    height: 54px;
    border: 1px solid #25325240 !important;
    border-radius: 11px !important;
    outline: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    cursor: pointer !important;
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) !important;
    background-repeat: no-repeat !important;
    background-size: 11px !important;
    background-position: calc(100% - 0.7em) center !important;
}
.login-field-input-text {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}
.mm-input-submit {
    text-align: center;
}

.mm-form-links {
    text-align: center;
    margin-top: 32px;
}
.mm-form-container {
    height: auto;
    width: 424px;
    opacity: 1;
    z-index: 1;
}
.login-field-input {
    display: flex;
    justify-content: space-between;
}

.mm-field-input-sm {
    width: 150px;
}
.login-field-label span {
    display: inline-block;
    outline: none;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
}

.mm-form-declaration {
    margin-left: 40%;
    background: var(--dayblue) 0% 0% no-repeat padding-box;
    background: #c3daea;
    border-radius: 8px;
    opacity: 1;
    padding: 16px;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background: none;
}
.MuiSvgIcon-root {
    outline: none;
}
.MuiTouchRipple-root {
    display: none !important;
    top: 0;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #6bce90 !important;
}

.MuiIconButton-colorPrimary:hover {
    background: none;
}

.MuiIconButton-root:hover,
.MuiIconButton-root:active {
    background: none;
}
.MuiCheckbox-root:hover {
    color: #6bce90 !important;
    background: none !important;
}
.MuiCheckbox-root {
    color: #25325240 !important;
}
.MuiFormControlLabel-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.contacts .MuiAccordionSummary-content p,
.messages .MuiAccordionSummary-content p {
    width: 18%;
    text-align: center;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0 !important;
    justify-content: center;
}

.contacts .MuiAccordionSummary-content p svg {
    width: 24px;
    height: 24px;
}
.messages .MuiAccordionSummary-content p {
    justify-content: flex-start !important;
    width: 30% !important;
}

.contacts .MuiAccordionSummary-content,
.messages .MuiAccordionSummary-content {
    box-sizing: border-box;
    margin: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
}
.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}
.MuiAccordion-root:before {
    display: none !important;
}
.contacts .MuiAccordionSummary-root {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    padding: 0px 27px 0px 32px;
}
.contacts .MuiAccordionSummary-content.Mui-expanded,
.messages .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}
.sideMenu .MuiAccordion-root.Mui-expanded,
.sideMenu .MuiAccordion-rounded.active {
    background: #ffffff40 0% 0% no-repeat padding-box !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #6bce90 !important;
}

.contacts .MuiAccordionSummary-content p:first-child {
    width: 45% !important;
    box-sizing: BORDER-BOX;
    text-align: center;
    display: flex;
    align-items: center;
    /* padding-left: 10px !important;*/
    max-width: 45%;
    justify-content: left;
}

.messages .MuiAccordionSummary-content p:first-child {
    width: 74% !important;
    box-sizing: BORDER-BOX;
    text-align: center;
    display: flex;
    align-items: center;
    max-width: 74%;
    max-width: 70%;
    justify-content: left;
}

.overlay-momentio {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(83 82 82 / 40%);
    z-index: 999999999999999;
    cursor: pointer;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    justify-content: center;
}
.sideMenu .MuiAccordionDetails-root {
    flex-direction: column;
    padding-left: 70px;
}
.sideMenu .MuiAccordion-rounded {
    background: transparent;
}
.contact-profile-icon {
    width: 25px !important;
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    margin: 0 20px;
}

span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1,
.MuiAutocomplete-noOptions,
.MuiAutocomplete-option,
.MuiChip-label {
    display: inline-block;
    padding: 0 8px 0 0;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
        var(--unnamed-line-spacing-24) var(--unnamed-font-family-raleway-medium);
    letter-spacing: var(--unnamed-character-spacing-0);
    /* color: var(--nightblue); */
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    margin-top: -3px;
}

.open::after {
    content: "<" !important;
    position: absolute;
    right: 0;
    position: absolute;
    right: 18px;
    transform: rotate(90deg);
}
.active {
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-21)
        var(--unnamed-font-family-raleway-bold) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    border-radius: 0px 22px 0px 0px;
    opacity: 1;
    text-decoration: underline;
    color: var(--nightblue);
}

.collapsible-sub-menu {
    background: none !important;
    margin-top: 0 !important;
    padding: 9px 0 !important;
}
.sideMenu .MuiAccordionSummary-content {
    margin-left: 10px !important;
}

.collapsible-sub-menu,
.collapsible-menu,
.collapsible-menu-1 {
    display: block;
    padding: 14px 0 15px 0;
    position: relative;
    padding-left: 15px;
    text-decoration: none;
    color: var(--nightblue);
    outline: none;
    text-align: left;
    background: none;
    width: 100%;
    font: var(--unnamed-font-style-normal) normal 500
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-21)
        var(--unnamed-font-family-raleway-medium) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    border: none;
}
.open {
    background: #ffffff40 0% 0% no-repeat padding-box !important;
    border-radius: 0px 22px 0px 0px;
}

.collapsible-menu-1.open {
    border-radius: 0px 22px 22px 0px;
}
.collapsible-menu-1::after {
    content: "" !important;
}
.sideMenu .MuiAccordionSummary-root {
    height: 40px;
    min-height: 48px !important;
    padding: 0px 23px;
}
.sideMenu {
    display: flex;
    margin-top: 14px;
}
.content {
    display: none;
    background: #ffffff40 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 22px 0px;
    overflow: hidden;
    padding-left: 45px;
}
.mm-login-container .dropdown-trigger {
    display: none !important;
}
.selectedContactChip {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 24px;
    margin-left: -5px;
    flex-wrap: wrap;
}

.selectedContactChip .MuiChip-label {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #233155 !important;
}

span.node-label {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway-semiBold);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #233155 !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #233155;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #233155;
}
input#size-small-standard-multi {
    /* width: 0 !important; */
    display: none !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiInputBase-marginDense.MuiInput-marginDense {
    height: auto !important;
    padding: 10px 50px 10px 10px !important;
}

.MuiTabs-indicator {
    display: none !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"]
    .MuiAutocomplete-input {
    margin-top: unset;
    border: 0 !important;
}

.message-chip {
    border-radius: 8px !important;
    background-color: #e9ebee !important;
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18)/24px
        var(--unnamed-font-family-raleway);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}

.message-chip:focus {
    border-radius: 8px !important;
    background-color: #e9ebee !important;
}

.momentio-tags {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18)/24px
        var(--unnamed-font-family-raleway);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    border: 2px solid var(--nightblue) !important;
    border-radius: 8px !important;
    background: none !important;
}

.momentio-tags:focus {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18)/24px
        var(--unnamed-font-family-raleway);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
    border: 2px solid var(--nightblue) !important;
    border-radius: 8px !important;
    background: none !important;
}

.selected-tags {
    background-color: var(--nightblue) !important;
    color: #fff !important;
}

.selected-tags:focus {
    background-color: var(--nightblue) !important;
    color: #fff !important;
}

.momentio-tags .MuiChip-label,
.message-chip .MuiChip-label {
    font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) var(--unnamed-font-size-18)/24px
        var(--unnamed-font-family-raleway);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nightblue);
}

.selected-tags .MuiChip-label {
    color: #fff !important;
}

@media only screen and (max-width: 1276px) {
    .contactListSection {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway) !important;
    }
    .contactListSection.mm-login-header {
        font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-16) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway-bold) !important;
    }
    .header24Medium {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-21) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-medium);
    }
    .login-field-label {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    select option {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }

    .login-field-input input,
    .login-field-input textarea,
    .login-field-input select,
    .login-field-input .MuiInputBase-root.MuiInput-root.MuiInput-underline,
    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .ReactFlagsSelect-module_flagsSelect__2pfa2 ul li {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .momentio-text-normal {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-medium);
    }
    .momentio-text-bold {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-bold);
    }
    .login-field-input-text {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .login-field-label span {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1,
    .MuiAutocomplete-noOptions,
    .MuiAutocomplete-option,
    .MuiChip-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .active {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-21)
            var(--unnamed-font-family-raleway-bold) !important;
    }
    .collapsible-sub-menu,
    .collapsible-menu,
    .collapsible-menu-1 {
        font: var(--unnamed-font-style-normal) normal 500
            var(--unnamed-font-size-16) / var(--unnamed-line-spacing-21)
            var(--unnamed-font-family-raleway-medium) !important;
    }

    .selectedContactChip .MuiChip-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-12) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-semiBold);
    }

    span.node-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-semiBold);
    }
    .message-chip {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/24px
            var(--unnamed-font-family-raleway);
    }
    .momentio-tags {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/24px
            var(--unnamed-font-family-raleway);
    }
    .momentio-tags:focus {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/24px
            var(--unnamed-font-family-raleway);
    }
    .momentio-tags .MuiChip-label,
    .message-chip .MuiChip-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/24px
            var(--unnamed-font-family-raleway);
    }
}

@media only screen and (max-width: 960px) {
    .contactListSection {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway) !important;
    }
    .contactListSection.mm-login-header {
        font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-14) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway-bold) !important;
    }
    .header24Medium {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-18) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-medium);
    }
    .login-field-label {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-bold);
    }
    select option {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }

    .login-field-input input,
    .login-field-input textarea,
    .login-field-input select,
    .login-field-input .MuiInputBase-root.MuiInput-root.MuiInput-underline,
    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .ReactFlagsSelect-module_flagsSelect__2pfa2 ul li {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .momentio-text-normal {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-medium);
    }
    .momentio-text-bold {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-30)
            var(--unnamed-font-family-raleway-bold);
    }
    .login-field-input-text {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .login-field-label span {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1,
    .MuiAutocomplete-noOptions,
    .MuiAutocomplete-option,
    .MuiChip-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-medium);
    }
    .active {
        font: var(--unnamed-font-style-normal) normal bold
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-21)
            var(--unnamed-font-family-raleway-bold) !important;
    }
    .collapsible-sub-menu,
    .collapsible-menu,
    .collapsible-menu-1 {
        font: var(--unnamed-font-style-normal) normal 500
            var(--unnamed-font-size-14) / var(--unnamed-line-spacing-21)
            var(--unnamed-font-family-raleway-medium) !important;
    }

    .selectedContactChip .MuiChip-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-10) /
            var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-raleway-semiBold);
    }

    span.node-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-600) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-35)
            var(--unnamed-font-family-raleway-semiBold);
    }
    .message-chip {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/24px
            var(--unnamed-font-family-raleway);
    }
    .momentio-tags {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/24px
            var(--unnamed-font-family-raleway);
    }
    .momentio-tags:focus {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/24px
            var(--unnamed-font-family-raleway);
    }
    .momentio-tags .MuiChip-label,
    .message-chip .MuiChip-label {
        font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-medium) var(--unnamed-font-size-14)/24px
            var(--unnamed-font-family-raleway);
    }
}
