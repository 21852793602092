.ewill .momentio-text-bold {
    word-break: break-word;
}
.ewill input,
.ewill textarea,
.ewill select,
.ewill .MuiInputBase-root.MuiInput-root.MuiInput-underline,
.ewill .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    width: 85% !important;
    resize: none;
    width: 85%;
}

.ewill th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall {
    font: normal normal bold 18px/35px Raleway-Bold !important;
    width: 25%;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #233155 !important;
    padding: 10px 25px 10px 0 !important;
}
.ewill tbody .MuiTableCell-root {
    text-align: left;
    font: normal normal 600 14px/24px Raleway-SemiBold !important;
    letter-spacing: 1.4px;
    padding: 10px 25px 10px 0 !important;
}
.ewill .MuiTableContainer-root {
    border-radius: 0 !important;
}

.n_header-4 a {
color: inherit !important;
}

.ewill .MuiAccordion-root {
    border-bottom-left-radius: 0px !important;
    border-bottom: 1px solid #23315540;
    border-bottom-right-radius: 0px !important;
    position: relative;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.ewill .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
}

.ewill .accordion-content-option-label {
    margin-right: 15px;
    width: 30%;
    word-break: break-word;
}

.ewill .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    padding: 20px 32px 32px 85px;
    margin-top: -25px;
    min-height: 50px;
}

.ewill .MuiAccordionSummary-root {
    box-sizing: border-box;
    padding-left: 28px;
}

.ewill .MuiAccordionSummary-root {
    display: flex;
    padding: 0px 34px;
}

.ewill .MuiAccordionSummary-content {
    margin: 0;
}

.ewill .MuiIconButton-edgeEnd {
    height: 22px;
    width: 22px;
    color: #233155;
}

.ewill {
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
    border-radius: 24px;
    width: 100%;
    margin-bottom: 40px;
}
.ewill .task-completed {
    font-size: 32px;
    color: rgb(107, 206, 144);
    margin: -4px 16px -4px 0px;
}
.login-field-input .task-completed {
    color: rgb(107, 206, 144);
    font-size: 32px !important;
}

.ewill .secondary-alert-sm {
    right: 6%;
    position: absolute;
    right: 45px;
    margin-left: 0;
    cursor: pointer;
}

.ewill
    .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd
    svg {
    width: 20px;
}

.sideMenu .MuiAccordionSummary-expandIcon {
    width: 22px;
    height: 22px;
    margin-right: 6px;
}

.ewill .contact-profile-icon {
    width: 44px !important;
    height: 44px !important;
    background: white;
    border-radius: 50%;
    padding: 10px;
    margin: 0 16px 0 8px;
    box-sizing: border-box;
}
.ewill .task-not-completed {
    font-size: 32px;
    margin: -4px 16px -4px 0px;
    color: rgb(35, 49, 85);
    opacity: 0.1;
}
.ewill .mm-login-header {
    display: flex;
    align-items: center;
}
.ewill .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}

.MuiDrawer-root .MuiDrawer-paper {
    width: 80%;
}
.ewill .overviewContainer {
    display: flex;
    padding: 18px 22px;
    background: #f1f6f9 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin-bottom: 8px;
    position: relative;
    width: 95%;
    box-sizing: border-box;
}
.overviewContainer {
    display: flex;
    padding: 18px 22px;
    background: #f1f6f9 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin-bottom: 8px;
    position: relative;
    width: 95%;
    box-sizing: border-box;
}

.ewill .overviewMenu {
    position: absolute;
    right: 10px;
    margin-top: 5px;
    cursor: pointer;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.custom-file-input::before {
    content: "Select some files";
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}
.custom-file-input:hover::before {
    border-color: black;
}
.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.password-error {
    display: flex;
    padding-right: 15px !important;
    flex-direction: column;
    align-items: center;
}

.password-error div{
    background: #f45b5b 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 8px 16px #23315540;*/
    opacity: 1;
    text-align: left;
    border-radius: 8px;
    text-align: left;
    border-radius: 8px;
    opacity: 1;
    padding: 16px;
    color: #fff;
   
}
.password-error span {
    color: #fff;
}

.personalOverview {
    display: flex;
    /* height: 60px; */
    background: 0% 0% no-repeat padding-box padding-box rgb(241, 246, 249);
    border-radius: 8px;
    align-items: center;
    margin-bottom: 8px;
    width: 95%;
    position: relative;
    padding: 10px 5px;
    align-items: center;
    box-sizing: border-box;
}

.contactListSection {
    text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway) !important;
    /* margin-left: 15px; */
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
}
.contactListSection.mm-login-header {
  text-align: left;
    font: var(--unnamed-font-style-normal) normal bold
        var(--unnamed-font-size-18) / var(--unnamed-line-spacing-35)
        var(--unnamed-font-family-raleway-bold) !important;
    /* margin-left: 15px; */
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
}
.messages .contactListSection {
    margin-left: 0 !important;
    /* padding: 12px 0px;*/
}

.messages .MuiAccordionSummary-root.Mui-disabled,
.contacts .MuiAccordionSummary-root.Mui-disabled {
    opacity: 1 !important;
}

.usernameFirst {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

.contact-section .contacts-headers {
    min-width: 180px;
}
.side-menu-header {
    width: 310px;
    min-height: calc(100vh - 260px);
}
.show-flex-sm {
    display: none;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(1) !important;
    color: #233155 !important;
    font-size: 12px !important;
}
.show-flex-lg {
    display: flex;
}
.auth-content {
    width: 100%;
    padding: 0px 6%;
    min-height: calc(100vh - 260px);
}
.sideMenu a:focus,
.sideMenu a:hover {
    color: #23527c;
    text-decoration: none !important;
}
@media only screen and (max-width: 1024) {
}
